import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import { graphql, Link } from 'gatsby'
import comicGreenBG from "../images/comic-green-background-small.svg"
import comicBlueBG from "../images/comic-blue-background-small.svg"
import { ImHome } from "react-icons/im"
// import Img from 'gatsby-image'

const BlogList = ({data, location, pageContext}) => {
    const postsfull = data.blogdata
    const { currentPage, numPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage -1 === 1 ? "/blog/" : ("/blog/"+(currentPage -1).toString())
    const nextPage = ("/blog/"+(currentPage +1).toString())
 
    return (
        <>
            <Layout location={location}>
                <SEO title="Web Design and Development News from our Blog" description="Read all the latest news from our Website Design and Development Blog Posts. Web Design and development news that you can use to improve your website designs." />
                <PageHero heading="Latest News you can Use..." />
                <section className="container mx-auto text-center">
                    <div className="px-4 pt-4">
                        <ul className="text-left font-mono text-xs md:text-base">
                            <li className="inline-block border-black border-r-2">
                            <Link to="/">
                                <span className="inline-block pr-2"><ImHome /></span>
                                <span className="px-2">Home</span>
                            </Link>
                            </li>
                            <li className="inline-block">
                                <span className="px-2">Blog</span>
                            </li>
                        </ul>
                    </div>
                    <div className="comic">
                        {postsfull.edges.map((edge, index) => {
                            const { frontmatter, fields } = edge.node
                            const alternateBG = index %2 === 0
                            let comicBG
                            alternateBG? comicBG = comicBlueBG : comicBG = comicGreenBG
                            return (
                                <div key={edge.node.id} className="w-full">
                                    <Link to={fields.slug}>
                                    <div className="panel pulserev" style={{backgroundImage: "url(" + comicBG + ")", backgroundSize: "cover", overflow: "hidden",}}>
                                        <p className="text top-left text-lg md:text-xl z-20">
                                            {frontmatter.date}
                                        </p>
                                        <div className="flex-auto flex justify-between flex-col">
                                            {/* <div className="flex items-center px-3 py-3 lg:pr-0 lg:w-1/3">
                                                {frontmatter.featureimage.extension === 'svg'?
                                                <img className="flex-1 border-white border-2 shadow-lg" src={frontmatter.featureimage.publicURL} alt={frontmatter.featureimagealt} />
                                                :
                                                <Img className="flex-1 border-white border-2 shadow-lg" fluid={frontmatter.featureimage.childImageSharp.fluid} alt={frontmatter.featureimagealt} />
                                                }
                                            </div> */}
                                            <div className="flex text-left">
                                                <div className="flex-1 speech text-base sm:text-xl 2xl:text-2xl pb-6">
                                                    <h2 className="text-xl sm:text-2xl 2xl:text-4xl py-3 font-mono">{frontmatter.title}</h2>
                                                    <p>{edge.node.excerpt}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="flex flex-col justify-between sm:flex-row pt-8">
                        <div className="text-left pr-4">
                            {!isFirst && (
                                <Link className="pulse heading-box-text-blog1 text-2xl px-6" to={prevPage}><span>Previous Page</span></Link>
                            )}
                        </div>
                        <div className="text-right pl-4">
                            {!isLast && (
                                <Link className="pulse heading-box-text-blog2 text-2xl px-6" to={nextPage}><span>Next Page</span></Link>
                            )}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default BlogList

export const query = graphql`
query($skip: Int!, $limit: Int!) {
    blogdata: allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/(blog)/.*[.]md$/"}}, 
        sort: {fields: [frontmatter___date], order: DESC}, 
        limit: $limit, 
        skip: $skip
      ) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            featureimagealt
            featureimage {
                childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
                extension
                publicURL
            }
          }
        }
      }
    }
  }
`